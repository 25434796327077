<template>
  <div>
    <b-modal id="modal-add" title="Tambah Sub Kegiatan" ok-disabled no-close-on-backdrop hide-footer size="lg">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-form-group label="Sasaran">
              <validation-provider #default="{ errors }" rules="required" name="Indikator Tujuan">
                <v-select v-model="input.id_sasaran" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="sasaran"
                  @input="LoadIndikator(input.id_sasaran)" :options="sasaran" :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Indikator Sasaran" v-show="input.id_sasaran != ''">
              <validation-provider #default="{ errors }" rules="required" name="Indikator Tujuan">
                <v-select v-model="input.id_indikator" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="indikator_sasaran" :options="indikator_sasaran" :state="errors.length > 0 ? false : null"
                  @input="LoadKegiatan()" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Kegiatan " v-show="input.id_sasaran != '' && input.id_indikator != ''">
              <validation-provider #default="{ errors }" rules="required" name="kegiata">
                <v-select v-model="input.id_kegiatan" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  @input="loadSubKegiatan()" label="nama_kegiatan" :options="kegiatan"
                  :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Sub Kegiatan " v-show="input.id_kegiatan != ''">
              <validation-provider #default="{ errors }" rules="required" name="Sub Kegiatan">
                <v-select v-model="input.id_sub_kegiatan" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="nm_sub_kegiatan" :options="sub_kegiatan" :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <hr>
            <b-col cols="12">
              <b-button variant="primary" type="submit" @click.prevent="validationForm" size="sm">
                <feather-icon icon="SaveIcon" class="mr-50" size='15' /> Submit
              </b-button>
              <b-button variant="warning" @click.prevent="close()" class="mr-1 ml-1" size="sm">
                <feather-icon icon="RefreshCcwIcon" class="mr-50" size='15' /> Batal
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

  </div>
</template>

<script>
import axios from '@/config/Axios'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert, BFormGroup, BFormInput } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BModal, BFormGroup, BFormInput, vSelect,
    BAlert, ValidationProvider, ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      required,
      input: {
        id_sub_kegiatan: '', id_kegiatan: '', id_sasaran: '', id_indikator: ''

      },
      indikator_sasaran: [],
      sub_kegiatan: [],
      kegiatan: []
    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: ["load_data"],
  methods: {
    async LoadKegiatan() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/setup/renja/get_kegiatan',
        data: {
          id_indikator: self.input.id_indikator.id
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.kegiatan = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async loadSubKegiatan() {
      this.input.id_sub_kegiatan = {};
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/setup/renja/get_kegiatan_sub',
        data: {
          id_kegiatan: self.input.id_kegiatan.id_kegiatan
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.sub_kegiatan = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async LoadIndikator() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/setup/renja/indikator_sasaran',
        data: {
          kode_tujuan: self.input.id_sasaran.kode_tujuan,
          kode_sasaran: self.input.id_sasaran.kode_sasaran
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.indikator_sasaran = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async simpanRenja() {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/pu/setup/renja/insert_rejna',
        data: {
          "id_sub_kegiatan": self.input.id_sub_kegiatan.id_sub_kegiatan,
          "id_indikator": self.input.id_indikator.id
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.notification('warning', "info", response.data.message);

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.simpanRenja();
          this.$emit("load_data");
          this.close()
        }
      })
    },
    close() {
      this.input = {}
      this.$root.$emit('bv::hide::modal', 'modal-add')
    }
  },
  props: {
    sasaran: {},
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>