<template>
  <b-row>
    <b-col md="1" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <label class="d-inline-block   "></label>
        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" />
      </b-form-group>
    </b-col>
    <b-col md="4" class="my-1">
      <b-form-group label="" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col md="4" sm="8" class="my-1">

    </b-col>

    <b-col md="3" class="my-1">
      <b-button variant="primary" block size="sm" v-b-modal.modal-add>
        <feather-icon icon="PlusCircleIcon" class="mr-50" size='15' /> tambah Sub Kegiatan
      </b-button>
    </b-col>

    <b-col cols="12">
      <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items" :fields="fields"
        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
        :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(wbs)="data">
          <b-row>
            <b-col md="7">
              <strong> {{ data.item.kode_sub_kegiatan }}</strong>
              {{ data.item.nama_sub_kegiatan }} <small> <strong>( Satuan {{ data.item.satuan }})</strong></small>
              <!-- <br><small>Tujuan : {{ data.item.tujuan }} </small>
              <br><small>Sasaran : {{ data.item.sasaran }} </small> -->

            </b-col>
            <b-col md="3">
              <small>Pagu Anggaran</small>
              <h3 class="text-primary"><sup>Rp.</sup><strong> {{ Rp(data.item.pagu_anggaran) }}</strong></h3>
              <b-row>
                <b-col>
                  <b-button variant="outline-primary" size="sm" block v-b-modal.modal-1
                    @click="KeloalaBalaiBidang(data.item)">
                    <feather-icon icon="GridIcon" size='15' /> Detail SubKegiatan
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="2">
              <b-button variant="success" block size="sm" v-b-modal.modal-2 @click="KeloalaBalaiBidang(data.item)">
                Kelola Balai/Bidang
              </b-button>
              <b-row class="mt-1">
                <b-col md="6"> <b-button variant="outline-warning" block size="sm" v-b-modal.modal-edit
                    @click="KeloalaBalaiBidang(data.item)">
                    <feather-icon icon="EditIcon" class="mr-50" size='15' />
                  </b-button></b-col>
                <b-col md="6"> <b-button variant="outline-danger" block size="sm" @click="hapusRenja(data.item)">
                    <feather-icon icon="TrashIcon" class="mr-50" size='15' />
                  </b-button></b-col>
              </b-row>
            </b-col>
          </b-row>
        </template>
      </b-table>
    </b-col>

    <b-col cols="6">
      Jumlah Sub Kegiatan <strong>{{ items.length }}</strong>
    </b-col>
    <b-col cols="6">
      <b-pagination v-model="currentPage" :total-rows="items.length" :per-page="perPage" align="right" size="sm"
        class="my-0" />
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton, BAlert,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton, BAlert,
  },
  props: {
    rsData: {

    }
  },
  emits: ['KeloalaBalaiBidang', 'hapusRenja'],
  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [{
        key: 'wbs',
        label: '',
        thStyle: {
          display: 'none'
        }
      }],
    }
  },
  computed: {
    items: {
      get: function () {
        return this.rsData;
      },
      set: function (newValue) {
        return this.rsData = newValue;
      }
    },

    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key
        }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    Rp(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    KeloalaBalaiBidang(val) {
      this.$emit("KeloalaBalaiBidang", val)
    },
    hapusRenja(val) {
      this.$emit("hapusRenja", val)
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
<style>
.btn-block {
  display: block;
  width: 100%;
}

.mtm {
  margin-top: -5px;
}
</style>